<template>
	<div class="main-contents">
		<div class="tit">공급기업 정보관리<img src="/images/admin/list_shortcut.png" alt="목록가기" @click="movePage('back')"/></div>
		<div class="sub-tit"><p>공급기업 정보<span v-if="corpSeq == ''">등록</span><span v-else>수정</span></p></div>
		<div class="enter_inform">
			<div class="form">
				<div class="label">회사명</div>
				<div class="list">
					<InputComp v-model="corpInfo.corpNm" title="회사명" maxByte="300" :rules="{'required':true}" />
				</div>
			</div>
			<div class="form">
				<div class="label">사업자등록번호</div>
				<div class="list">
					<InputComp type="bizNo" v-model="corpInfo.bizRegNo" title="사업자등록번호" :rules="{'required':true}"/>
				</div>
			</div>
			<div class="form industry">
				<div class="label">산업분야</div>
					<div class="list">
						<SelectComp cdId="PRO105" v-model="corpInfo.indtypeClassCd" title="산업분야" :rules="{'required':true}"/>
					</div>
				<div class="label emp">직원수</div>
				<InputComp v-model="corpInfo.empCnt" maxlength="5"/>
			</div>
			<div class="form zip">
				<div class="label">우편번호</div>
				<div class="list">
					<InputComp v-model="corpInfo.companyZipNo" title="우편번호" :rules="{'required':true}" readonly/>
				</div>
				<div class="btn" @click="jusoPop()">주소검색</div>
			</div>
			<div class="form">
				<div class="label">주소</div>
				<div class="list">
					<InputComp v-model="corpInfo.companyAddr" title="주소" :rules="{'required':true}" readonly/>
				</div>
			</div>
			<div class="form">
				<div class="label">상세주소</div>
				<div class="list">
					<InputComp v-model="corpInfo.companyAddrDtl" title="상세주소" :rules="{'required':true}" readonly/>
				</div>
			</div>
			<div class="form">
				<div class="label">홈페이지</div>
				<div class="list">
					<InputComp v-model="corpInfo.homepageAddr" maxlength="300"/>
				</div>
			</div>	
		</div>
		<div class="select_btn">
			<div class="btn_01" @click="movePage('back')">취소</div>
			<div class="btn_02" v-if="corpSeq == ''" @click="setSupplyCorpInfo()">등록</div>
			<div class="btn_02" style="background-color: #FF681D;" v-else @click="setSupplyCorpInfo()">수정</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			corpInfo : {
				corpNm : '',
				bizRegNo : '',
				indtypeClassCd : '',
				empCnt : '',
				companyZipNo : '',
				companyAddr : '',
				companyAddrDtl : '',
				homepageAddr : '',
			},

			corpSeq : this.$route.params.corpSeq || '',
		}
	},

	beforeMount() {
		//console.log('forward', this.$route.params);
		if(this.corpSeq != '') this.getDemandCorpInfo();
	},

	methods : {
		getDemandCorpInfo() {
			var param = {};
			param.corpSeq = this.corpSeq;

			this.$.httpPost('/api/mem/adm/dco/getDemandCorpInfo', {corpSeq : this.corpSeq})
				.then(res => {
					this.corpInfo = res.data.corpInfo || {};
				}).catch(this.$.httpErrorCommon);
		},

		setSupplyCorpInfo() {
			var param = {};
			param.div = 'sco';
			
			this.$.popup('/adm/mem/MEM921P02', param)
				.then(res => {
					if(res) {
						this.$.httpPost('/api/mem/adm/sco/setSupplyCorpInfo', this.corpInfo)
							.then(() => {
								this.movePage();
							}).catch(this.$.httpErrorCommon);
					}
				})
		},

		// 주소검색
		jusoPop() {
			this.$.jusoSearch(this, function(this_obj, res) {
				this_obj.corpInfo.companyZipNo   = res.zipNo;
				this_obj.corpInfo.companyAddr	 = res.roadAddrPart1;
				this_obj.corpInfo.companyAddrDtl = res.addrDetail + " " + res.roadAddrPart2;
				this_obj.$forceUpdate();
			});
		},

		movePage(div) {
			if(div == 'back') this.$router.go(-1);
			else this.$router.push('MEM935M01');
		},
	}
}
</script>